import React from 'react';

import Button from '@rotaryintl/harris-button';

import { OneColumn } from '@components/Layouts/OneColumn';
import AccessDenied from '@components/Pages/AccessDenied';
import NotFound from '@components/Routing/NotFound';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';

import { useTranslation } from '@external/react-i18next';
import { getDistrictIdFromPath } from '@use-cases/districts';
import { getDistrictFinancePath } from '@use-cases/districts/helpers/getDistrictFinancePath';
import { localizedNavigate } from '@utils/localized-navigate';

interface BackButtonProps {
  t: (key: string, defaultValue?: string) => string;
  onBackClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ t, onBackClick }) => (
  <Button
    clickHandler={onBackClick}
    icon="fas-angle-left"
    size="md"
    variant="text"
    className="mt-6"
  >
    {t('back-link-text', 'Back')}
  </Button>
);

interface Props {
  isManager: boolean;
  districtId: string;
}

const ViewClubBalanceReport: React.FC<Props> = ({ isManager, districtId }) => {
  const { t } = useTranslation();
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  const storedActiveTabDataDistrict = window.localStorage.getItem(
    'activeDistrictTabValue'
  );
  const activeTabDataDistrict = storedActiveTabDataDistrict
    ? JSON.parse(storedActiveTabDataDistrict)
    : [];
  const navigatedFromDistrictActiveTab = activeTabDataDistrict[0]?.value;

  const handleBackNavigation = () => {
    let redirectToDistrictFinancePath = '/my-clubs';
    if (navigatedFromDistrictActiveTab) {
      const homeDistrictId = getDistrictIdFromPath(
        navigatedFromDistrictActiveTab
      );
      redirectToDistrictFinancePath = getDistrictFinancePath(homeDistrictId);
    }
    localizedNavigate(redirectToDistrictFinancePath);
  };

  if (!isManager) {
    return (
      <OneColumn className="mb-20">
        <BackButton t={t} onBackClick={handleBackNavigation} />
        <AccessDenied />
      </OneColumn>
    );
  }

  return (
    <OneColumn className="mb-20">
      <BackButton
        t={t}
        onBackClick={() =>
          localizedNavigate(getDistrictFinancePath(districtId))
        }
      />
      <h1 className="font-semibold text-xl-38 mobile-m:text-xl-48 tablet:text-xl-58 tablet:p-0 m-0 my-6 tablet:mb-8 tablet:leading-xl-58">
        {t(
          'district-invoice.daily-club-balance-report.title',
          'Daily Club Balance Report'
        )}
      </h1>
      <div id="DailyClubBalanceDownloadReport">
        <span>
          {t(
            'district-invoice.daily-club-balance-report-download-msg',
            'We are downloading daily club balance report for you and opening it in new tab.'
          )}
        </span>
      </div>
    </OneColumn>
  );
};

export default ViewClubBalanceReport;
